import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout page={"error"}>
    <SEO title="404: Not found"/>
    <div className="wrapper _ta-center">
      <h1>Diese Seite existiert nicht.</h1>
      <br/>
      <Link href={"/"} className={"h3"}>Zurück zur Startseite</Link>
    </div>
  </Layout>
)

export default NotFoundPage
